import * as React from 'react';
import { Footer } from 'grommet';
import config from '../../config';
import Socials from './Socials';
import { Box } from 'grommet';
import { FaAdobe, FaAws } from 'react-icons/fa';
import { DiReact, DiSass, DiNodejs, DiGit } from 'react-icons/di';
const sizeCreated = '1em';

const FooterStack = () => (
  <Footer justify="end">
    <ul key="footerList">
      <li key="socialList">
        <Box direction="row">
          <Socials />
        </Box>
      </li>
      {/*       <li key="footerCopyright" style={{ paddingTop: '.5em' }}>
        <Box>
          <pre>2020 &copy; Peter Henson Valdes II</pre>
        </Box>
      </li> */}
      {/*   <li
        
        key="stackList"
        style={{ paddingTop: '.5em' }}
      >
        <Box direction="row" gap="xxsmall" justify="center">
          <img
            alt="gatsby icon"
            className="icon"
            loading="lazy"
            src={config.imageCDNpreURL + 'Gatsby_Monogram_White.png'}
            style={{ width: '20px' }}
          />{' '}
          <img
            alt="netlify icon"
            className="icon"
            loading="lazy"
            src={config.imageCDNpreURL + 'netlify-dark.png'}
            style={{ width: '20px' }}
          />{' '}
          <DiReact size={sizeCreated} className="icon" />{' '}
          <DiSass size={sizeCreated} className="icon" />{' '}
          <FaAdobe size={sizeCreated} className="icon" />{' '}
          <DiGit size={sizeCreated} className="icon" />{' '}
          <DiNodejs size={sizeCreated} className="icon" />{' '}
          <FaAws size={sizeCreated} className="icon" />{' '}
        </Box>{' '}
      </li> */}
    </ul>
  </Footer>
);

export default FooterStack;
