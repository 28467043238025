module.exports = {
  siteTitle: 'Peter Henson Valdes II', // <title>
  location: 'Los Angeles, CA',
  roles: 'A Creator',
  manifestName: 'peter henson valdes ii',
  manifestShortName: 'peter valdes ii', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'minimal-ui',
  manifestIcon: 'static/anim-icon.gif',
  /*   heading: 'Hello',
  heading2: `World`,
  subHeading: 'beepboop',
 */
  heading: 'Peter Henson Valdes II',
  heading2: ``,
  subHeading: 'A Creative',
  imageCDNpreURL: `https://d3dchd37g3pi7w.cloudfront.net/`,
  socialLinks: [
    {
      icon: 'fa-instagram',
      name: 'IG',
      comp: `<FaInstagram />`,
      url: 'https://instagram.com/petervaldesii'
    },
    {
      icon: 'fa-github',
      name: 'Github',
      comp: `<FaGithubSquare />`,
      url: 'https://github.com/petervaldesii'
    },
    {
      icon: 'fa-facebook-square',
      name: 'Facebook',
      comp: `<FaFacebookSquare />`,
      url: 'https://www.facebook.com/peterhensonvaldesii'
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      comp: `<MdEmail />`,
      url: 'mailto:peter.valdesii@ucla.edu'
    }
  ]
};
